const images = [
  {
    id: 1,
    src: 'karly-jones-xBqYLnRhfaI-unsplash.jpg',
    title: 'foundation',
    description: 'Makeup-fdation',
  },
  {
    id: 2,
    src: 'karly-jones-K3p8QlzBf7M-unsplash.jpg',
    title: 'blush',
    description: 'Makeup-lip',
  },
  {
    id: 3,
    src: 'jake-peterson-NZUYV-NezV0-unsplash.jpg',
    title: 'eyeshadow',
    description: 'makeup-eye',
  },
  {
    id: 4,
    src: 'caroline-attwood-E1rH__X9SA0-unsplash.jpg',
    title: 'lipstick',
    description: 'makeup-blush',
  },
]

function imageLoader() {
  return images
}

export default imageLoader
